import * as React from 'react';
import Layout from './src/components/Layout/Layout';
import "./src/styles/global.css";
import { GatsbySSR } from 'gatsby';

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  const googleAnalyticsId = process.env.GOOGLE_ANALYTICS_ID;
  if (!document.documentElement.getAttribute("lang")) {
    document.documentElement.setAttribute("lang", "en"); // Set a default language
  }

  // Ensure the script is added to the document head or body
  const script = document.createElement('script');
  script.src = 'https://cdn.jsdelivr.net/npm/tarteaucitronjs@latest/tarteaucitron.js';
  script.async = true;
  document.body.appendChild(script);

  script.onload = () => {
    // Initialize tarteaucitron after the script is loaded
    window.tarteaucitron?.init({
      "privacyUrl": "/privacy-policy",
      "hashtag": "#tarteaucitron",
      "cookieName": "tarteaucitron",
      "orientation": "bottom",
      "showAlertSmall": false,
      "cookieslist": true,
      "adblocker": false,
      "AcceptAllCta": true,
      "highPrivacy": false,
      "handleBrowserDNTRequest": false,
      "removeCredit": false,
      "moreInfoLink": true,
      "useExternalCss": false,
      "readmoreLink": "/cookie-policy",
    });
    
    // window.tarteaucitron.services.googleanalytics = {
    //   "key": "googleanalytics",
    //   "type": "analytic",
    //   "name": "Google Analytics",
    //   "uri": "https://policies.google.com/privacy",
    //   "needConsent": true,
    //   "cookies": ['_ga', '_gid', '_gat'],
    //   "js": function () {
    //     if (window.tarteaucitron?.user?.gajs) {
    //       window.tarteaucitron.addScript('https://www.googletagmanager.com/gtag/js?id=' + window.tarteaucitron.user.gajs);
    //       window.dataLayer = window.dataLayer || [];
    //       function gtag() { window.dataLayer.push(arguments); }
    //       gtag('js', new Date());
    //       gtag('config', window.tarteaucitron.user.gajs);
    //     }
    //   }
    // };
    window.tarteaucitron.user.googletagmanagerId = 'G-3R94KBEZHR';
    (window.tarteaucitron.job = window.tarteaucitron.job || []).push('googletagmanager');
  };
};
